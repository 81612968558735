<template>
  <div v-if="user" class="d-flex">
    <div class="flex-grow-1">
      <div class="d-flex align-center">
        <div>
          <h5 class="text-h5 mb-1 font-weight-bold">
            What would you like to order?
          </h5>
        </div>
      </div>
      
      <preloader v-if="status.getting || gettingProducts" message="Please wait..." />
      
      <!-- TYPES -->
      <v-alert v-if="!status.getting && !forms.length" class="mt-5" type="info" border="left">
        No request type available. Update your request package.
      </v-alert>

      <v-fade-transition 
        v-if="!status.getting && forms.length"
        class="mt-10 d-flex gap-10 flex-wrap" 
        group
      >
        <FormCard
          v-for="form in forms"
          :key="form.id"
          :form="form"
          @selected="selected(form)"
          :route="user.role === 'client' ? { name: 'OrderPage', query: { id: form.id }} : { name: 'CreteOrderForm', params: { id: form.id }, query: { view: 'editor' } }"
        />
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash'
import { mapState, mapActions } from 'vuex'

import FormCard from './components/FormCard'

export default {
  metaInfo: {
    title: 'Create Request'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      orderBy,
      newId: null,
      submitted: false,
      errorDialog: false,
      gettingPackage: true,
      gettingProducts: null, 
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      forms: state => state.orderform.forms,
      status: state => state.orderform.status,
    }),

    confForms: function () {
      let forms = this.forms
      return forms
    },
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    FormCard,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('orderform', [
      'getForms',
    ]),
    
    selectProduct() {
      this.getFields({
        type: this.$route.query.t,
        product: this.$route.query.p
      })
    },

    // CHECK IF TYPE IS CLIENT 
    // PLAN INCLUDED IN PACKAGE
    inPackage(id) {
      if (this.user && this.user.role === 'client') {
        var packages = []
        var included = false

        if (this.user.requestPackages && this.user.requestPackages.length) {
          packages = packages.concat(this.user.requestPackages)
        }
        else if (this.user.requestPackage) {
          packages.push(this.user.requestPackage)
        }

        packages.forEach(pkg => {
          if (this.$store.getters['request/rPackage'](pkg).types.includes(id)) {
            included = true
          }
        })

        return included
      }
      else {
        return true
      }
    },

    /*------------------------------------------------------------------------------
     * SELECTED
     *----------------------------------------------------------------------------*/
    selected(form) {
      console.log(form.id)
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getForms()
  }
}
</script>